<template>
  <Row>
    <Col :lg="{span: 12}" :md="{span: 24}">
      <div class="chart" ref="foodWeightChart"></div>
    </Col>
    <Col :lg="{span: 12}" :md="{span: 24}">
      <div class="chart" ref="foodCalChart"></div>
    </Col>
  </Row>
</template>
<script type='text/ecmascript-6'>
import echarts from 'echarts';
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			foodTypeList: ['早餐', '中餐', '晚餐', '加餐'],
			foodCalChart: '',
			foodWeightChart: '',
			Charts: '',
			foodCalData: [
				{
					value: '',
					name: '早餐',
				},
				{
					value: '',
					name: '中餐',
				},
				{
					value: '',
					name: '晚餐',
				},
				{
					value: '',
					name: '加餐',
				},
			],
			foodWeightData: [
				{
					value: '',
					name: '早餐',
				},
				{
					value: '',
					name: '中餐',
				},
				{
					value: '',
					name: '晚餐',
				},
				{
					value: '',
					name: '加餐',
				},
			],
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		foodRecordId() {
			return this.$route.params.food_id;
		},
	},
	created() {
		this.handleInit();
	},

	methods: {
		handleInit() {
			MemberService.detailFood({ id: this.foodRecordId }).then((data) => {
				this.foodWeightData[0].value = data.weight.b_weight;
				this.foodWeightData[1].value = data.weight.l_weight;
				this.foodWeightData[2].value = data.weight.d_weight;
				this.foodWeightData[3].value = data.weight.a_weight ? data.weight.a_weight : 0;

				this.foodCalData[0].value = data.cal.b_cal;
				this.foodCalData[1].value = data.cal.l_cal;
				this.foodCalData[2].value = data.cal.d_cal;
				this.foodCalData[3].value = data.cal.a_cal ? data.cal.a_cal : 0;
				this.loadChart();
			});
		},

		drawChart(title, nameList, data, flag) {
			// echarts实例化
			if (flag === 1) {
				this.foodWeightChart = echarts.init(this.$refs.foodWeightChart);
			} else {
				this.foodCalChart = echarts.init(this.$refs.foodCalChart);
			}
			// 初始化实例
			var options = {
				title: {
					text: title,
					subtext: '统计',
					x: 'center',
					y: 'center',
					textStyle: {
						fontWeight: 'normal',
						fontSize: 18,
					},
				},
				tooltip: {
					formatter: function (params) {
						if (flag === 1) {
							return (
								params.marker + params.name + ': ' + params.value + 'g(' + params.percent + '%)'
							);
						} else {
							return (
								params.marker + params.name + ': ' + params.value + 'kcal(' + params.percent + '%)'
							);
						}
					},
					axisPointer: {
						type: 'cross',
						animation: false,
						label: {
							backgroundColor: '#505765',
						},
					},
				},
				legend: {
					orient: 'vertical',
					bottom: '40%',
					right: '15%',
					data: nameList,
				},
				series: [
					{
						// name:'访问来源',
						type: 'pie',
						radius: ['45%', '70%'],
						selectedMode: 'single',
						color: ['#FFCC67', '#59ADF3', '#FF999A', '#86D560'],
						avoidLabelOverlap: false,
						label: {
							normal: {
								position: 'inner',
								formatter: '{d}%',
								textStyle: {
									color: '#fff',
									fontWeight: 'bold',
									fontSize: 10,
								},
							},
							emphasis: {
								show: true,
								textStyle: {
									fontSize: '30',
									fontWeight: 'bold',
								},
							},
						},
						labelLine: {
							normal: {
								show: false,
							},
						},
						data: this.foodCalData,
					},
				],
			};
			// return options
			if (flag === 1) {
				this.foodWeightChart.setOption(options);
			} else {
				this.foodCalChart.setOption(options);
			}
			// charts.setOption(options)
		},
		loadChart() {
			this.foodCalChart.setOption({
				series: [
					{
						// 根据名字对应到相应的系列
						data: this.foodCalData,
					},
				],
			});

			this.foodWeightChart.setOption({
				series: [
					{
						// 根据名字对应到相应的系列
						data: this.foodWeightData,
					},
				],
			});
		},
	},
	mounted() {
		this.drawChart('重量比', this.foodTypeList, this.foodWeightData, 1);
		this.drawChart('能量比', this.foodTypeList, this.foodCalData, 0);
	},
};
</script>
<style lang='css' scoped>
</style>
