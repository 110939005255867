<template>
  <div class="list-contain">
    <div class="scheme-contain">
      <div class="food-date">
        <Row>
          <Col :lg="{span: 1, offset: 9}" :md="{span: 2, offset: 8}"><span class="text_in">日期：</span></Col>
          <Col :lg="{span: 8}" :md="{span: 8}"><span class="text_in text_date">{{ base.create_time }}</span></Col>
        </Row>
      </div>
      <!-- 早餐能量 -->
      <div class="food-module">
        <div class="food-title-panel">
          <h2>早餐能量：<em class="text-title">{{ base.breakfast }}kcal</em></h2>
        </div>
        <div class="food-list">
          <Row :gutter="55">
            <Col :lg="{span: 4}" :md="{span: 8}" v-for="item in breakfast_detail" :key="item">
              <div class="food-box">
                <img :src="item.photo" onerror="this.src='@/assets/img/imate.png'" alt="" width="100%" height="123px">
                <div class="food-type">
                  <span>{{ item.name }}</span>
                </div>
                <div class="food-enery">
                  <Row>
                    <Col :lg="{span: 12}" :md="{span: 24}"><span class="text_in">{{ item.total_enery }}kcal</span></Col>
                    <Col :lg="{span: 12}" :md="{span: 24}" class="tar"><span class="text_in"><em class="num">{{ item.count }}</em>份</span></Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <!-- 中餐能量 -->
      <div class="food-module">
        <div class="food-title-panel">
          <h2>中餐能量：<em class="text-title">{{ base.lunch }}kcal</em></h2>
        </div>
        <div class="food-list">
          <Row :gutter="55">
            <Col :lg="{span: 4}" :md="{span: 8}" v-for="item in lunch_detail" :key="item">
              <div class="food-box">
                <img :src="item.photo" onerror="this.src='@/assets/img/imate.png'" alt="" width="100%" height="123px">
                <div class="food-type">
                  <span>{{ item.name }}</span>
                </div>
                <div class="food-enery">
                  <Row>
                    <Col :lg="{span: 12}" :md="{span: 24}"><span class="text_in">{{ item.total_enery }}kcal</span></Col>
                    <Col :lg="{span: 12}" :md="{span: 24}" class="tar"><span class="text_in"><em class="num">{{ item.count }}</em>份</span></Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <!-- 晚餐能量 -->
      <div class="food-module">
        <div class="food-title-panel">
          <h2>晚餐能量：<em class="text-title">{{ base.dinner }}kcal</em></h2>
        </div>
        <div class="food-list">
          <Row :gutter="55">
            <Col :lg="{span: 4}" :md="{span: 8}" v-for="item in dinner_detail" :key="item">
              <div class="food-box">
                <img :src="item.photo" onerror="this.src='@/assets/img/imate.png'" alt="" width="100%" height="123px">
                <div class="food-type">
                  <span>{{ item.name }}</span>
                </div>
                <div class="food-enery">
                  <Row>
                    <Col :lg="{span: 12}" :md="{span: 24}"><span class="text_in">{{ item.total_enery }}kcal</span></Col>
                    <Col :lg="{span: 12}" :md="{span: 24}" class="tar"><span class="text_in"><em class="num">{{ item.count }}</em>份</span></Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <!-- 加餐能量 -->
      <div class="food-module">
        <div class="food-title-panel">
          <h2>加餐能量：<em class="text-title">{{ base.add }}kcal</em></h2>
        </div>
        <div class="food-list">
          <Row :gutter="55">
            <Col :lg="{span: 4}" :md="{span: 8}" v-for="item in add_detail" :key="item">
              <div class="food-box">
                <img :src="item.photo" onerror="this.src='@/assets/img/imate.png'" alt="" width="100%" height="123px">
                <div class="food-type">
                  <span>{{ item.name }}</span>
                </div>
                <div class="food-enery">
                  <Row>
                    <Col :lg="{span: 12}" :md="{span: 24}"><span class="text_in">{{ item.total_enery }}kcal</span></Col>
                    <Col :lg="{span: 12}" :md="{span: 24}" class="tar"><span class="text_in"><em class="num">{{ item.count }}</em>份</span></Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <!-- 膳食重量比 -->
    <div class="total-food-percent">
      <FoodRecord-Chart></FoodRecord-Chart>
    </div>
    <div class="symbol-box tac">
      <Button type="primary" size="large" @click="handleBack">返回</Button>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import FoodRecordChart from 'pages/member/member-detail/FoodRecordDetail-Chart';
import MemberService from '@/services/memberService';
export default {
	components: {
		'FoodRecord-Chart': FoodRecordChart,
	},
	data() {
		return {
			breakfast_detail: [],
			lunch_detail: [],
			dinner_detail: [],
			add_detail: [],
			base: {
				dinner: 0,
				lunch: 0,
				add: 0,
				create_time: '',
				breakfast: 0,
			},
			cal: {},
		};
	},

	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		foodRecordId() {
			return this.$route.params.food_id;
		},
	},

	created() {
		MemberService.detailFood({ id: this.foodRecordId }).then((data) => {
			this.breakfast_detail = data.breakfast_detail;
			this.lunch_detail = data.lunch_detail;
			this.dinner_detail = data.dinner_detail;
			this.add_detail = data.add_detail;
			this.base = data.base;
			this.cal = data.cal;
		});
	},

	methods: {
		handleBack() {
			this.$router.go(-1);
		},
	},
};
</script>
<style lang="css" scoped>
.list-contain .scheme-contain {
  padding-bottom: 30px;
}
.text_date {
  color: #5886e6;
  font-size: 20px;
}
.food-module .food-title-panel {
  padding: 10px 0 10px 20px;
  background: #f3f3f3;
  margin: 25px 15px 0;
  border: 1px solid #eee;
  border-bottom: 1px solid #fdad00;
}
.food-module .text-title {
  font-size: 14px;
}
.food-module .num {
  color: #f8645c;
  padding-right: 5px;
}
.total-food-percent {
  margin-top: 15px;
  margin-bottom: 42px;
  background: #fff;
  padding: 25px 0 15px 35px;
}
</style>
